import * as React from 'react';
import { Stack, Heading, Box, Button, Flex } from '@chakra-ui/react';
import { getConfig } from '../../../config';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

const config = getConfig();
const demoBookingUrl = `${config.demoBookingUrl}`;

export const BookDemo: React.FC = () => {
  const mixpanel = useMixpanel();
  return (
    <Flex justifyContent="center" bg="#EBF1FF" py="60px">
      <Box maxW="900px" px={4}>
        <Stack spacing={6}>
          <Heading size="lg">Still have questions?</Heading>
          <Heading size="md">
            Book in a demo to get your personalised product tour and answers
          </Heading>
          <Box>
            <Button
              size="lg"
              as="a"
              px={10}
              href={demoBookingUrl}
              target="_blank"
              rel="noreferrer noopener"
              onClick={(): void => {
                mixpanel.track('Partner page: clicked "book a demo"');
              }}
            >
              Book a demo
            </Button>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
};
