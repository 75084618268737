import * as React from 'react';
import { Stack, Heading, Text, Box, Flex } from '@chakra-ui/react';
import { Link } from 'gatsby';
import ZapIcon from '../../../assets/zap-blue.svg';

export const Faq: React.FC = () => {
  return (
    <Flex justifyContent="center" py="60px" px={2}>
      <Stack spacing={10} maxW="800px">
        <Heading textAlign="center">Frequently asked questions</Heading>
        <Stack direction="row" spacing={3}>
          <Box mt={2}>
            <ZapIcon width={20} />
          </Box>
          <Stack spacing={5}>
            <Heading size="md" color="#00257A">
              How does Canyou charge?
            </Heading>
            <Text fontSize="18px">
              Monthly in advance from the date you start your plan.
            </Text>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={3} justifyContent="center">
          <Box mt={2}>
            <ZapIcon width={20} />
          </Box>
          <Stack spacing={5}>
            <Heading size="md" color="#00257A">
              What if I cancel?
            </Heading>
            <Text fontSize="18px" maxW="800px">
              We never lock you in. We also make it easy to get your data out of
              Canyou. Once you cancel your subscription, Canyou will refund you
              the balance of your prepaid month.
            </Text>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={3} justifyContent="center">
          <Box mt={2}>
            <ZapIcon width={20} />
          </Box>
          <Stack spacing={5}>
            <Heading size="md" color="#00257A">
              What about data, security and privacy?
            </Heading>
            <Text fontSize="18px" maxW="800px">
              Canyou values our users&apos; data and security. Full details can be
              found on our support page{' '}
              <Link to="/support/frequently-asked-questions#security">
                here.
              </Link>
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
