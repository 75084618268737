import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import { Button, Heading, Stack, Box } from '@chakra-ui/react';
import { Layout } from '../../components/Layout';
import { SEO } from '../../components/SEO';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { Integrations } from '../../components/pages/partner/integrations';
import { Benefits } from '../../components/pages/partner/benefits';
import { Faq } from '../../components/pages/partner/faq';
import { BookDemo } from '../../components/pages/partner/book-demo';
import { ContactUs } from '../../components/pages/home/ContactUs';
import { getConfig } from '../../config';
import { PricingSection } from '../../components/PricingSection';

type Data = {
  heroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  partnerImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const config = getConfig();
const signupUrl = `${config.platformUrl}/signup`;

const Partner: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Partner page: Viewed Partner Page');
  }, [mixpanel]);

  const { heroImage, partnerImage } = useStaticQuery<Data>(
    graphql`
      {
        heroImage: file(relativePath: { eq: "hero-background.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        partnerImage: file(relativePath: { eq: "partner/blocks.png" }) {
          childImageSharp {
            gatsbyImageData(width: 360, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );

  const bgImage = convertToBgImage(heroImage.childImageSharp.gatsbyImageData);

  return (
    <Layout>
      <SEO
        title="Canyou Partner Program | Make client communication easy"
        description="Make your processes more efficient and safe. Add value to your practice and access discounted pricing"
      />
      <BackgroundImage
        Tag="section"
        style={{
          width: '100%',
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        fadeIn
        {...bgImage}
      >
        <Stack
          padding={{ base: 0, md: '50px' }}
          direction={{ base: 'column-reverse', lg: 'row' }}
          alignItems="center"
          spacing="15px"
          justifyContent="center"
        >
          <Box maxW="350px" my={3}>
            <GatsbyImage
              alt="partner"
              image={partnerImage.childImageSharp.gatsbyImageData}
            />
          </Box>
          <Stack direction="column" maxWidth="800px" spacing="40px">
            <Heading
              as="h1"
              size="lg"
              mt={3}
              lineHeight="lg"
              textAlign={{ base: 'center', lg: 'left' }}
            >
              Canyou Partner Program
            </Heading>
            <Heading size="md" textAlign={{ base: 'center', lg: 'left' }}>
              Make interacting with your business clients a breeze.
            </Heading>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={3}
              alignItems="center"
            >
              <Button
                size="lg"
                as="a"
                minWidth="210px"
                href={signupUrl}
                onClick={(): void => {
                  mixpanel.track('Partner Page: clicked "start free trial"');
                }}
              >
                Try for free
              </Button>
              <Button
                size="lg"
                minWidth="210px"
                variant="outline"
                onClick={(): void => {
                  mixpanel.track('Partner page: clicked "join the program"');
                  const section = document.getElementById('contact_section');
                  if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                Join the program
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </BackgroundImage>
      <Integrations />
      <Benefits />
      <PricingSection />
      <Faq />
      <BookDemo />
      <ContactUs
        headingText="Join the Partner Program"
        bodyText="If you are ready to get started, please send us a message and we will get back to you as soon as possible"
      />
    </Layout>
  );
};

export default Partner;
