import * as React from 'react';
import { Flex, Stack, Box, Heading } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type Data = {
  xeroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  deputyImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  myobImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const Integrations: React.FC = () => {
  const { xeroImage, deputyImage, myobImage } = useStaticQuery<Data>(
    graphql`
      {
        xeroImage: file(relativePath: { eq: "xero-connected.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        deputyImage: file(
          relativePath: { eq: "deputy-logo-full-color-rgb.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        myobImage: file(relativePath: { eq: "myob-logo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  return (
    <Stack bg="#EBF1FF" py="60px" spacing={6}>
      <Heading size="md" textAlign="center">
        Integrate with your existing apps
      </Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={5}
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          boxShadow="0px 0px 10px #0000004D"
          borderRadius="5px"
          width="280px"
          height="120px"
          justifyContent="center"
          alignItems="center"
          bg="white"
        >
          <Box maxWidth="180px">
            <GatsbyImage
              alt="xero"
              image={xeroImage.childImageSharp.gatsbyImageData}
            />
          </Box>
        </Flex>
        <Flex
          boxShadow="0px 0px 10px #0000004D"
          borderRadius="5px"
          width="280px"
          height="120px"
          justifyContent="center"
          alignItems="center"
          bg="white"
        >
          <Box maxWidth="180px">
            <GatsbyImage
              alt="myob"
              image={myobImage.childImageSharp.gatsbyImageData}
            />
          </Box>
        </Flex>
        <Flex
          boxShadow="0px 0px 10px #0000004D"
          borderRadius="5px"
          width="280px"
          height="120px"
          justifyContent="center"
          alignItems="center"
          bg="white"
        >
          <Box maxWidth="180px">
            <GatsbyImage
              alt="deputy"
              image={deputyImage.childImageSharp.gatsbyImageData}
            />
          </Box>
        </Flex>
        <Flex
          boxShadow="0px 0px 10px #0000004D"
          borderRadius="5px"
          width="280px"
          height="120px"
          justifyContent="center"
          alignItems="center"
          bg="white"
        >
          <Box>
            <Heading size="md" textAlign="center">
              More
              <br /> coming soon!
            </Heading>
          </Box>
        </Flex>
      </Stack>
    </Stack>
  );
};
