import * as React from 'react';
import { Stack, Box, Heading, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type Data = {
  cogsImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  growingImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  targetImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const Benefits: React.FC = () => {
  const { cogsImage, growingImage, targetImage } = useStaticQuery<Data>(
    graphql`
      {
        cogsImage: file(relativePath: { eq: "partner/cogs.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        growingImage: file(relativePath: { eq: "partner/growing.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        targetImage: file(relativePath: { eq: "partner/target.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
      }
    `,
  );
  return (
    <Box py="60px">
      <Heading size="md" textAlign="center" pb={10}>
        Join the Canyou Partner Program and
      </Heading>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={8}
      >
        <Stack
          spacing={4}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="280px">
            <GatsbyImage
              alt="cogs"
              image={cogsImage.childImageSharp.gatsbyImageData}
            />
          </Box>
          <Stack direction="column" maxWidth="670px">
            <Heading size="md" textAlign={{ base: 'center', md: 'left' }}>
              Make your processes more efficient and safe
            </Heading>
            <Text textAlign={{ base: 'center', md: 'left' }}>
              Use Canyou for your business clients to improve efficiency and
              save time on data entry. Use best practices to securely transfer
              sensitive info like TFND (no more text messages or emails!)
            </Text>
          </Stack>
        </Stack>
        <Stack
          spacing={4}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="280px">
            <GatsbyImage
              alt="target"
              image={targetImage.childImageSharp.gatsbyImageData}
            />
          </Box>
          <Stack direction="column" maxWidth="670px">
            <Heading size="md" textAlign={{ base: 'center', md: 'left' }}>
              Add value to your practice
            </Heading>
            <Text textAlign={{ base: 'center', md: 'left' }}>
              Canyou makes interactions between business owners, bookkeepers and
              employees super easy, making you look like a superstar.
            </Text>
          </Stack>
        </Stack>
        <Stack
          spacing={4}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="280px">
            <GatsbyImage
              alt="growing"
              image={growingImage.childImageSharp.gatsbyImageData}
            />
          </Box>
          <Stack direction="column" maxWidth="670px">
            <Heading size="md" textAlign={{ base: 'center', md: 'left' }}>
              Access competitive pricing
            </Heading>
            <Text textAlign={{ base: 'center', md: 'left' }}>
              Get discounts for connecting multiple client businesses. Canyou
              grows with your practice so only pay for what you need.
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
